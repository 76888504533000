export const extractTopic = (url) => {
  url = url.replace(/\/$/, "");
  const regex = /\/topics\/(.*)/;
  const match = url.match(regex);
  if (match && match[1]) {
    const words = match[1].split("-");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  } else {
    return "";
  }
};

const extractTime = (timestamp) => {
  const timeRegex = /t=(\d+)s/;
  const match = timeRegex.exec(timestamp);
  if (match) {
    const totalSeconds = parseInt(match[1]);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return ` [${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}]`;
  }
  return " 00:00:00";
};

export const formatUrlTitle = (url) => {
  if (url.includes("zendesk")) {
    return "Help Center";
  }

  const timeRegexp = /^(https?:\/\/[^/]+\/[^#]+)\/#t=(\d+)s\.?$/;
  const match = url.match(timeRegexp);

  if (match) {
    const baseUrl = match[1];
    const time = extractTime(`t=${match[2]}s`);
    const words = baseUrl.split("/").pop();
    const formattedTitle = words
      .split("-")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    return formattedTitle + time + ".";
  }

  return url;
};

export const removeLastDot = (url) => {
  if (url?.endsWith(".")) {
    return url.slice(0, -1);
  }
  return url;
};

export const extractTimestamp = (url) => {
  const timestampRegex = /#t=(\d+)s/;
  const match = timestampRegex.exec(url);
  if (match) {
    return parseInt(match[1]);
  }
  return 0;
};

export const playbackVideo = () => {
  const checkInterval = setInterval(() => {
    try {
      const time = extractTimestamp(getCurrentUrl());
      const videoPlayerElement = document.getElementById("drtalks-player");
      if (videoPlayerElement && videoPlayerElement?.player) {
        videoPlayerElement.player.ready(async function () {
          await videoPlayerElement.player.currentTime(time);

          videoPlayerElement.player.play();
          clearInterval(checkInterval);
        });
      }
    } catch (error) {
      console.log("Playback error:", error);
      clearInterval(checkInterval);
    }
  }, 1000);
};

export const getCurrentUrl = () => {
  const getUrl = (url) => {
    switch (url) {
      case "http://localhost:3001/":
        return "https://drtalks.com/topics/cancer/";
      case "http://localhost:3000/":
        return "https://drtalks.com/topics/cancer/";
      default:
        return url;
    }
  };
  const url = getUrl(window.location.href);
  return url;
};

export const formatZendeskUrl = (text) => {
  return text.split(/(https?:\/\/\S+)/g).map((part, index) =>
    part.match(/(https?:\/\/\S+)/g) ? (
      <a
        key={index}
        href="https://drtalks.zendesk.com/hc/en-us"
        target="_blank"
        rel="noopener noreferrer"
        className="urlStyle"
      >
        {formatUrlTitle(part)}
      </a>
    ) : (
      <span key={index}>{part}</span>
    )
  );
};

export const formatTimestampUrl = (text) => {
  const timestampRegex = /(.*?)(https?:\/\/[^\s]+)(.*)/;

  const linkRegex = /(https?:\/\/\S+)/g;

  const parts = text.split(timestampRegex);
  return parts.map((part, index) =>
    timestampRegex.test(part) ? (
      <a
        key={index}
        href={removeLastDot(
          part
            .split(linkRegex)[1]
            .replace("drtalks", "drtalksdev.wpenginepowered")
        )} // add replace for dev
        // href={removeLastDot(part.split(linkRegex)[1])} // add replace for dev
        rel="noopener noreferrer"
        className="urlStyle"
        onClick={() => {
          if (window.location.href.includes("t=")) {
            playbackVideo();
          }
        }}
      >
        {part.split(linkRegex).map((part, index) => {
          return formatUrlTitle(part);
        })}
      </a>
    ) : (
      <span key={index}>{part}</span>
    )
  );
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const getMessageBoxClass = (position) => {
  return `messageCustom${position === "left" ? "Left" : "Right"}`;
};

export const getStyle = (position) => ({
  display: "flex",
  flexDirection: position === "left" ? "row" : "row-reverse",
  marginBottom: "1px",
  maxWidth: "475px",
  border: "1p solid black",
  gap: "8px",
});

export const renderMessageWithTimestamp = (text) => {
  if (!text || typeof text !== "string") return text;

  if (text.includes("Empty Response"))
    return <span key={"emptys"}>I cannot answer this question</span>;

  const formattedText = text.includes("https://drtalks.zendesk.com/hc/en-us")
    ? formatZendeskUrl(text)
    : formatTimestampUrl(text);

  return <span>{formattedText}</span>;
};

export const getMetaId = () => {
  const metaAttributes = ["summit-name", "summit-id", "summit-slug"];

  const metaData = metaAttributes.map((attribute) => {
    const metaElement = document.querySelector(`meta[name=${attribute}]`);
    return metaElement?.getAttribute("content") || "";
  });

  return metaData;
};

export const getVideoTitles = () => {
  const videoItemDetails = document.querySelectorAll(".video-item-details");
  const h3Texts = [];
  videoItemDetails.forEach((element) => {
    const h3Elements = element.querySelectorAll("h3");
    h3Elements.forEach((h3) => {
      h3Texts.push(h3.innerText);
    });
  });

  return h3Texts;
};

export const getVideoTitlesElementor = () => {
  const videoItemDetails = document.querySelectorAll(
    ".elementor-widget-container"
  );
  const h3Texts = [];
  videoItemDetails.forEach((element) => {
    const h3Elements = element.querySelectorAll("h3");
    h3Elements.forEach((h3) => {
      if (h3.innerText.includes("Video:")) {
        h3Texts.push(h3.innerText);
      }
    });
  });

  return h3Texts;
};

export const getVideoNames = () => {
  const names =
    getVideoTitles().length !== 0
      ? getVideoTitles()
      : getVideoTitlesElementor();

  return names;
};
